<template>
  <b-dropdown v-model="selectedLanguage" aria-role="list" position="is-bottom-left">
    <fw-button-dropdown slot="trigger" type="transparent" uppercase :label="selectedLanguage" />
    <b-dropdown-item value="pt" aria-role="listitem">
      <span>Português</span>
    </b-dropdown-item>
    <b-dropdown-item value="en" aria-role="listitem">
      <span>English</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import store from '@/store'

export default {
  name: 'LanguageNav',
  data() {
    return {
      selectedLanguage: store.state.language || this.$i18n.locale,
    }
  },
  watch: {
    selectedLanguage() {
      store.dispatch('setLanguage', this.selectedLanguage)
      this.$i18n.locale = this.selectedLanguage
    },
  },
}
</script>
